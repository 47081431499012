@import "../../../node_modules/bootstrap/scss/bootstrap";
$theme-colors: (
  "primary": #00a2ff
);
$mygreen : #97bf68;
body {
  color: #333;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3{
	font-size:1.2rem;
}
h4{
	font-size:0.9rem;
}

p {
  margin: 0;
}

#meteoChartContainer{
  width:100%;
  position: relative; 
  height:50vh;
}

.clickable{
  cursor: pointer ;
}

.grid{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.8rem;
}
#communeContainer img {
 width: 50px;
}
.grid .card-body{
  padding: 0 0 0 10px;
}
.content {
  display: flex;
  margin: 82px auto 32px;
  padding: 0 16px;
  max-width: 960px;
  flex-direction: column;
  align-items: center;
}

.spacer {
  flex: 1;
}

.mini-spacer-left {
  margin-left:15px;
}

.text-center {
  text-align: center;
}

.toolbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #1976d2;
  color: white;
  font-weight: 600;
}

.toolbar img {
  margin: 0 16px;
}

.toolbar #twitter-logo {
  height: 40px;
  margin: 0 8px;
}

.toolbar #youtube-logo {
  height: 40px;
  margin: 0 16px;
}

.toolbar #twitter-logo:hover,
.toolbar #youtube-logo:hover {
  opacity: 0.8;
}

a,
a:visited,
a:hover {
  color: #1976d2;
  text-decoration: none;
}

a:hover {
  color: #125699;
}

.circle-link {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  margin: 8px;
  background-color: white;
  border: 1px solid #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: 1s ease-out;
}

.circle-link:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

footer {
  margin-top: 8px;
  display: flex;
  align-items: center;
  line-height: 20px;
}

footer a {
  display: flex;
  align-items: center;
}

footer#logos{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

#clouds{
  position: fixed;
  bottom: -160px;
  z-index: -10;
  width:100% ;
}

/* Responsive Styles */
@media screen and (max-width: 767px) {
  .card-container > *:not(.circle-link){
    width: 100%;
  }
  .spacer span{
    visibility:hidden;
    display:none;
  }
  .grid{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 575px) {
}
